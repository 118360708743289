/* Styles for .comment-table-container */
.comment-table-container {
  width: 70%;
  margin: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: -950px;
  margin-left: 300px !important; /* Aligned with qr-gen-container */
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 
}

/* Header and Search Bar */
.comment-table-header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.comment-table-search-bar {
  width: 68dvw;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.comment-table-header-title {
  background-color: #09099e;
  color: white;
  padding: 10px 10px;
  width: 100%;
  text-align: center;
  font-size: 2dvw;
  margin-bottom: 20px;
  border-radius: 4px;
}

.search-bar {
  display: flex; 
  justify-content: center; 
  margin-bottom: 20px;
  width: 100%; /* Ensures it takes full width of the container */
}

.search-bar input {
  width: 97%; /* Makes the input take the full width */
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
  transition: border-color 0.3s;
}

.search-bar input:focus {
  border-color: #007bff;
}


/* Table Styles */
.comment-table-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  overflow-x: hidden;
}

.comment-table-th-td {
  border: 1px solid #ddd;
  padding: 0.5dvw;
  text-align: left;
  white-space: nowrap;
  font-size: 1dvw;
}

.comment-table-th {
  background-color: #f2f2f2;
  font-weight: bold;
  text-align: center;
}

/* Button Styles */
.comment-table-comment-button,
.comment-table-view-comment-button {
  padding: 6px 12px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comment-table-comment-button {
  background-color: #28a745;
}

.comment-table-view-comment-button {
  background-color: #17a2b8;
  gap: 5px;
}

/* Pagination */
.comment-table-pagination,
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.comment-table-pagination-button,
.pagination-button {
  padding: 8px 12px;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  transition: background-color 0.3s ease;
}

.comment-table-pagination-button-active,
.pagination-button.active {
  background-color: #0056b3;
}
.comment-table-arrow-button {
  padding: 8px 12px;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #6c757d;
  color: white;
  border: none;
  transition: background-color 0.3s ease;
}

.comment-table-arrow-button-disabled {
  background-color: #cccccc;
  cursor: default;
}
.comment-table-arrow-button,
.pagination-button:hover:not(.active) {
  background-color: #6c757d;
  color: white;
  border: none;
  transition: background-color 0.3s ease;
}

.comment-table-arrow-button-disabled {
  background-color: #cccccc;
  cursor: default;
}



/* Media Queries for Responsive Design */
@media(max-width: 1489px) {
  .comment-table-container {
    margin-left: 25dvw;
  }
}

@media(max-width: 1207px) {
  .comment-table-container {
    margin-left: 29dvw;
  }
}

@media(max-width: 1039px) {
  .comment-table-container {
    margin-left: 35dvw;
  }
}

@media(max-width: 840px) and (max-height: 824px) {
  .comment-table-container {
    margin-top: 0px;
    margin-left: 7dvw;
  }
}

@media(max-height: 913px) {
  .comment-table-container {
    margin-top: -850px;
  }
}

@media(max-height: 722px) {
  .comment-table-container {
    margin-top: -650px;
  }
}

/* Styles for .qr-gen-container */
.qr-gen-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-top: -100vh;
  margin-left: 300px;
}

/* Header Styling */
.header {
  background-color: #09099e;
  color: white;
  padding: 10px 10px;
  width: 97%;
  text-align: center;
  font-size: 2dvw;
  margin-bottom: 20px;
  border-radius: 4px;
}

.header h1 {
  color: white;
  margin: 0;
  font-size: 24px;
}

/* Search Bar */
.search-bar-qr {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-bar-qr input {
  width: 97%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
  transition: border-color 0.3s;
}

.search-bar-qr input:focus {
  border-color: #007bff;
}

/* Employee Table */
.employee-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.employee-table th,
.employee-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  font-size: 1dvw;
}

.employee-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.employee-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* QR Button */
.qr-button {
  background-color: #28a745;
  color: white;
  padding: 5px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.qr-button:hover {
  background-color: #218838;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.back-button {
  background-color: #dc3545;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #c82333;
}

/* Media Queries for qr-gen-container */
@media(max-width: 999px) {
  .qr-gen-container {
    margin-left: 35dvw;
    margin-top: -550px;
  }
}

@media(max-width: 840px) {
  .qr-gen-container {
    margin: auto;
    margin-top: 0px;
  }

  .employee-table th,
  .employee-table td {
    font-size: 1.5dvw;
  }
}
