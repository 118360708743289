.comment-table-container {
  width: 70%;
  margin: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: -950px;
  margin-left: 300px !important; /* Aligned with qr-gen-container */
  display: flex; /* Changed to flex */
  flex-direction: column; /* Ensures that child elements stack vertically */
  align-items: center; /* Centers child elements horizontally */
  justify-content: center; /* Centers child elements vertically */
}
.comment-table-header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.comment-table-search-bar {
  width: 68dvw;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.comment-table-header-title {
  background-color: #09099e;
  color: white;
  padding: 10px 10px;
  width:100%;
  text-align: center;
  font-size: 2dvw;
  margin-bottom: 20px;
  border-radius: 4px;
}

.comment-table-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  overflow-x: hidden;
}

.comment-table-th-td {
  border: 1px solid #ddd;
  padding: 0.5dvw;
  text-align: left;
  white-space: nowrap;
  font-size: 1dvw;
}

.comment-table-th {
  background-color: #f2f2f2;
  font-weight: bold;
  text-align: center;
}

.comment-table-comment-button {
  padding: 6px 12px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.comment-table-view-comment-button {
  padding: 6px 12px;
  background-color: #17a2b8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.comment-table-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.comment-table-pagination-button {
  padding: 8px 12px;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  transition: background-color 0.3s ease;
}

.comment-table-pagination-button-active {
  background-color: #0056b3;
}

.comment-table-arrow-button {
  padding: 8px 12px;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #6c757d;
  color: white;
  border: none;
  transition: background-color 0.3s ease;
}

.comment-table-arrow-button-disabled {
  background-color: #cccccc;
  cursor: default;
}

@media(max-width: 1489px) {
  .comment-table-container {
    margin-left: 25dvw;
    
  }
}
@media(max-width: 1465px) {
  .comment-table-container {
    margin-left: 25dvw;
    
  }
}
@media(max-width: 1207px) {
  .comment-table-container {
    margin-left: 29dvw;
     
  }
}
@media(max-width: 1039px) {
  .comment-table-container {
    margin-left: 35dvw;
     
  }
}

/* Adjustments based on varying heights */
@media(max-height: 913px) {
  .comment-table-container {
    margin-top: -850px;
  }
}

@media(max-height: 830px) {
  .comment-table-container {
    margin-top: -830px; 
  }
}

@media(max-height: 792px) {
  .comment-table-container {
    margin-top: -800px;
  }
}

@media(max-height: 761px) {
  .comment-table-container {
    margin-top: -750px;
  }
}

@media(max-height: 722px) {
  .comment-table-container {
    margin-top: -650px;
  }
}

@media(max-height: 616px) {
  .comment-table-container {
    margin-top: -550px;
  }
}

@media(max-height: 518px) {
  .comment-table-container {
    margin-top: -450px;
  }
}

@media(max-width:840px) and (max-height:824px){
  .comment-table-container {
    margin-top: 00px;
  margin-left: 7dvw;
  }
}