/* General Container */
.Admin_view_edu_container-Hr {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin-top:-100vh;
    margin-left: 300px;
}

/* Search and Header */
.Admin_view_edu_search-add-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.Admin_view_edu_search-input-container {
    flex: 1;
    margin-right: 10px;
}

.Admin_view_edu_search-input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 6px;
    transition: border-color 0.3s;
}

.Admin_view_edu_search-input:focus {
    border-color: #007bff;
}

.Admin_view_edu_header-title-main {

    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    background-color: #09099e;
    color: white;
    padding: 10px 10px;
    width:100%;
    font-size: 2dvw;
    margin-bottom: 20px;
    border-radius: 4px;
}

/* Table Styles */
.Admin_view_edu_employee-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.Admin_view_edu_table-header {
    background-color: #f4f4f4;
}

.Admin_view_edu_table-header th,
.Admin_view_edu_employee-table th,
.Admin_view_edu_employee-table td {
    padding: 12px 16px;
    border-bottom: 1px solid #ddd;
    text-align: center;
    font-size: 14px;
}

.Admin_view_edu_even-row {
    background-color: #fafafa;
}

.Admin_view_edu_odd-row {
    background-color: #fff;
}

.Admin_view_edu_no-data {
    text-align: center;
    padding: 20px;
    color: #888;
    font-size: 16px;
}

/* Modal Styles */
/* Modal Overlay */
.Admin_view_edu_modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); /* Dark background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    
}

/* Modal Content */
.Admin_view_edu_modal-content {
    background: #fff;
    padding: 30px;
    border-radius: 12px;
    width: auto;
    max-width: 700px; /* Maximum width of modal */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    position: relative;
}

/* Modal Title */
.Admin_view_edu_modal-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center; /* Center title */
    border-bottom: 2px solid #007bff; /* Bottom border */
    padding-bottom: 10px;
}

/* Educational Details Table */
.Admin_view_edu_education-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.Admin_view_edu_education-table th,
.Admin_view_edu_education-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 14px;
}

.Admin_view_edu_education-table th {
    background-color: #f4f4f4; /* Header background */
    color: #333; /* Header text color */
}

.Admin_view_edu_education-table tr:nth-child(even) {
    background-color: #f9f9f9; /* Even row background */
}

.Admin_view_edu_education-table tr:nth-child(odd) {
    background-color: #fff; /* Odd row background */
}

/* Close Button */
.Admin_view_edu_close-button {
    padding: 10px 20px;
    background-color: #dc3545; /* Red background */
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 15px; /* Margin on top for spacing */
    display: block; /* Block display for centering */
    width: 100%; /* Full width */
}

.Admin_view_edu_close-button:hover {
    background-color: #c82333; /* Darker red on hover */
}

/* Responsive Styles for Modal */
@media (max-width: 576px) {
    .Admin_view_edu_modal-title {
        font-size: 20px; /* Smaller title on mobile */
    }

    .Admin_view_edu_close-button {
        font-size: 14px; /* Smaller button font size */
        padding: 8px 16px; /* Smaller button padding */
    }
}

/* Buttons */
.Admin_view_edu_view-button {
    padding: 8px 16px;
    font-size: 14px;
    background-color:#04AA6D;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.Admin_view_edu_view-button:hover {
    background-color: #0b4204;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .Admin_view_edu_container-Hr {
        padding: 15px;
    }
}

@media (max-width: 768px) {
    .Admin_view_edu_search-add-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .Admin_view_edu_search-input-container {
        margin-right: 0;
        margin-bottom: 10px;
    }
}

@media (max-width: 576px) {
    .Admin_view_edu_header-title-main {
        font-size: 22px;
    }

    .Admin_view_edu_view-button {
        font-size: 12px;
        padding: 6px 12px;
    }
}

@media (min-width: 840px) {
    .Admin_view_edu_container-Hr {
      padding: 20px;
      max-width: 1200px;
      box-sizing: border-box;
      margin-top: -100vh;
      
    }
  }

  @media (max-width: 840px) {
    .Admin_view_edu_container-Hr {
      padding: 20px;
      max-width: 1200px;
      box-sizing: border-box;
      margin-top: 0vh;
      margin-left: 0;
      
    }
  }
/* Pagination Controls */
.Admin_view_edu_pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; /* Space above pagination */
}

.Admin_view_edu_pagination-button {
    padding: 10px 15px;
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 5px; /* Space between buttons */
    transition: background-color 0.3s;
}

.Admin_view_edu_pagination-button:disabled {
    background-color: #ccc; /* Grey out button when disabled */
    cursor: not-allowed; /* Change cursor to indicate it's not clickable */
}

.Admin_view_edu_page-info {
    margin: 0 10px; /* Space between buttons and text */
    font-size: 16px;
}
