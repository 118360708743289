/* src/components/Topbarstyle.css */
@import url('https://fonts.googleapis.com/css2?family=Moul&display=swap');

.topbar {
  width: auto;
  height: 10vh;
  background-color: #01008A;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.logo-section {
  display: flex;
  align-items: center;
}

.logo {
  height: 50px;
  margin-left: 20px;
}

.h1 {
  font-family: 'Moul', sans-serif;
  font-size: 2em;
  color: #ffffff;
  margin-left: 20px;
}

.profile-section {
  display: flex;
  align-items: center;
}

.profile-icon {
  font-size: 2em;
  margin-right: 10px;
}

.admin-button {
  background-color: #ffffff;
  color: #01008A;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-right: 20px;
}

.admin-button:hover {
  background-color: #e9ecef;
  color: #01008A;
}

/* Responsive Design */
@media (max-width: 768px) {
  .logo {
    height: 40px;
  }

  .h1 {
    font-size: 1.5em;
    margin-left: 10px;
  }

  .profile-icon {
    font-size: 1.5em;
  }

  .admin-button {
    padding: 8px 16px;
    font-size: 0.9em;
    margin-right: 10px;
  }
}

@media (max-width: 480px) {
  .logo-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .logo {
    height: 35px;
    margin-left: 10px;
  }

  .h1 {
    font-size: 1.2em;
    margin-left: 10px;
    margin-top: 5px;
  }

  .profile-section {
    flex-direction: column;
    align-items: flex-end;
  }

  .profile-icon {
    font-size: 1.2em;
    margin-right: 5px;
  }

  .admin-button {
    padding: 6px 12px;
    font-size: 0.8em;
    margin-right: 5px;
  }
}
