/* Container and Layout */
.container-Hr {
    margin: 40px auto;
    font-family: 'Roboto', sans-serif;
    max-width: 1000px;
    background-color: #f8f9fb;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-top: -750px;
    margin-left: 350px;
}

/* Search and Add Button */
.search-add-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.search-input-container {
    position: relative;
    width: 75%;
}

.search-icon {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    color: #6c757d;
    font-size: 18px;
}

.search-input {
    width: 100%;
    padding: 10px 50px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 16px;
    color: #495057;
    background-color: #ffffff;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.search-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.add-employee-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.add-employee-button:hover {
    background-color: #0056b3;
}

/* Employee Table */
.employee-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
   
}

.table-header {
    background-color: #343a40;
    color: #0c0c0c;
    text-align: left;
}

.header-title {
    padding: 15px;
    font-size: 18px;
    text-transform: uppercase;
}

.table-subheader {
    background-color: #e9ecef;
    color: #495057;
    text-align: left;
}

.table-subheader th {
    padding: 12px;
}

.even-row {
    background-color: #f8f9fa;
}

.odd-row {
    background-color: #ffffff;
}

.employee-table td {
    padding: 12px;
    border-bottom: 1px solid #dee2e6;
    color: #212529;
}

.view-button {
    padding: 8px 16px;
    background-color: #28a745;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.view-button:hover {
    background-color: #218838;
}

.no-data {
    text-align: center;
    padding: 20px;
    color: #6c757d;
}

/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
}

/* Modal Content */
.modal-content {
    background-color: #ffffff;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    width: 100%;
    max-height: 80%;
    overflow-y: auto;
    text-align: left;
}

.modal-title {
    margin-bottom: 20px;
    font-size: 24px;
    color: #007bff;
    border-bottom: 2px solid #007bff;
    padding-bottom: 10px;
}

.details-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.details-label {
    padding: 10px;
    font-weight: bold;
    background-color: #f1f1f1;
    border-bottom: 1px solid #dee2e6;
}

.details-value {
    padding: 10px;
    background-color: #ffffff;
    border-bottom: 1px solid #dee2e6;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.update-button,
.close-button,
.save-button,
.cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.update-button {
    background-color: #ffc107;
    color: #ffffff;
}

.update-button:hover {
    background-color: #e0a800;
}

.close-button,
.cancel-button {
    background-color: #6c757d;
    color: #ffffff;
}

.close-button:hover,
.cancel-button:hover {
    background-color: #5a6268;
}

.save-button {
    background-color: #007bff;
    color: #ffffff;
}

.save-button:hover {
    background-color: #0056b3;
}

.update-input,
.update-select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    margin-bottom: 20px;
}
