.hr-manager-list {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  box-sizing: border-box;
  margin-top:-100vh;
  margin-left: 300px;
}

.search-bar-container {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.search-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.search-bar {
  padding: 10px !important;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex: 1;
}

.search-button {
  padding: 10px 15px;
  border: none;
  background-color: #E8E8FF;
  border: 1px solid #01008A;
  color: #01008A;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.add-button {
  padding: 7px 15px;
  border: none;
  background-color: #01008A;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.add-button:hover {
  background-color: #030345;
}

.hr-table {
  width: 100%;
  border-collapse: collapse;
}

.hr-table th,
.hr-table td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.hr-table th {
  background-color: #01008A;
  color: white;
}

.action-button {
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 0px 10px 0px grey;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.action-button.active {
  background-color: green;
  color: white;
}

.action-button.inactive {
  background-color: red;
  color: white;
}

.action-button.disabled {
  background-color: grey;
  color: white;
  cursor: not-allowed;
  opacity: 0.6;
}

.action-button:hover:not(.disabled) {
  transform: scale(1.05);
}

/* Responsive Design */
@media (min-width: 1400px) {
  .hr-manager-list {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    box-sizing: border-box;
    margin-top: -750px;
    margin-left: 300px;
    
  }
}
/*height*/
@media (min-height: 870px) {
  .hr-manager-list {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    box-sizing: border-box;
    margin-top: -950px;
    margin-left: 300px;
    
  }
}

@media (max-width: 1200px) {
  .hr-manager-list {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    box-sizing: border-box;
    margin-top: -700px;
    margin-left: 300px;
    
  }
}

@media (max-width: 1024px) {
  .search-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-bar {
    width: 100%;
    margin-bottom: 10px;
  }

  .search-button {
    width: auto;
    margin-bottom: 10px;
  }

  .add-button {
    width: 100%;
    padding: 10px;
    text-align: center;
  }
}
@media (max-width: 840px) {
  .hr-manager-list {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    box-sizing: border-box;
    margin-top: 0px;
    margin-left: 0px;
    
  }
}

@media (max-width: 640px) {
  .search-container {
    flex-direction: column;
  }

  .search-bar,
  .search-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .add-button {
    width: 100%;
    padding: 15px;
    margin-bottom: 10px;
  }

  .hr-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  th,
  td {
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .hr-manager-list {
    padding: 10px;
  }

  .search-bar,
  .search-button,
  .add-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .hr-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  th,
  td {
    padding: 6px;
  }
}
