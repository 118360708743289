
  
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .forgot-password-container {
    display: flex;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    max-width: 900px;
    width: 100%;
  }
  
  .left-side-cp {
    padding: 40px;
    flex: 1;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .left-side-cp .logo {
    width: 100px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .left-side-cp h1 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
  }
  
  .left-side-cp p {
    margin: 5px 0;
    font-size: 14px;
  }
  
  .left-side-cp h2 {
    margin: 20px 0;
    font-size: 20px;
    font-weight: bold;
  }
  
  .forgot-password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .input-container {
    position: relative;
    width: 100%;
    margin: 10px 0;
  }
  
  .icon-2 {
    position: absolute;
    left: 10px;
    top: 35%;
    transform: translateY(-50%);
    color: #888;
  }
  
  .input-field {
    width: 90%;
    padding: 10px 10px 10px 40px; /* Add padding to make space for the icon */
    border: 1px solid #e5e6eb;
    border-radius: 5px;
    background-color: #d9dbe2;
  }
  
  .error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    display: block;
  }
  
  .change-submit-button {
    width: 50%;
    padding: 8px;
    background-color: #01008A;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
  }
  .change-submit-button:hover{
    background-color: #0056b3;
    transform: scale(1.05);
  }
  .back-to-login {
    align-self: center;
    margin-top: 20px;
    font-size: 12px;
    color: #020a12;
    font-weight: bolder;
    text-decoration: none;
  }
  
  .right-side-cp {
    flex: 1;
    background: linear-gradient(to right, #01008A, #a933ff);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 40px;
  }
  
  .info-box {
    text-align: center;
  }
  
  .info-image {
    margin-top: 20px;
    width: 300px;
    height: auto;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .forgot-password-container {
      flex-direction: column;
      max-width: 100%;
    }
  
    .left-side-cp, .right-side-cp {
      width: 100%;
      padding: 20px;
    }
  
    .left-side-cp .logo {
      width: 80px;
    }
  
    .left-side-cp h1 {
      font-size: 20px;
    }
  
    .left-side-cp h2 {
      font-size: 18px;
    }
  
    .submit-button {
      width: 70%;
    }
  }
  
  @media (max-width: 480px) {
    .left-side-cp h1 {
      font-size: 18px;
    }
  
    .left-side-cp h2 {
      font-size: 16px;
    }
  
    .info-image {
      width: 100%;
      height: auto;
    }
  }