/* General Container */
.view_children_container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin-top:-100vh;
     margin-left: 300px;
}

/* Search and Header */
.view_children_search-add-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.view_children_search-input-container {
    flex: 1;
    margin-right: 10px;
}

.view_children_search-input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 6px;
    transition: border-color 0.3s;
}

.view_children_search-input:focus {
    border-color: #007bff;
}

.view_children_header-title-main {
 
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    background-color: #09099e;
    color: white;
    padding: 10px 10px;
    width:100%;
    font-size: 2dvw;
    margin-bottom: 20px;
    border-radius: 4px;
}

/* Table Styles */
.view_children_employee-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.view_children_table-header {
    background-color: #f4f4f4;
}

.view_children_table-subheader th,
.view_children_employee-table th,
.view_children_employee-table td {
    padding: 12px 16px;
    border-bottom: 1px solid #ddd;
    text-align: center;
    font-size: 14px;
}

.view_children_even-row {
    background-color: #fafafa;
}

.view_children_odd-row {
    background-color: #fff;
}

.view_children_no-data {
    text-align: center;
    padding: 20px;
    color: #888;
    font-size: 16px;
}
.view_children_view-button {
    background-color: #04AA6D; /* Primary blue color */
    color: white; /* White text */
    padding: 10px 20px; /* Increased padding for a better look */
    border: none; /* No border */
    border-radius: 6px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
    font-size: 16px; /* Font size */
    font-weight: 500; /* Slightly bolder text */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Hover Effect */
.view_children_view-button:hover {
    background-color:  #0b4204; /* Darker blue on hover */
    transform: translateY(-2px); /* Slight lift effect */
}

/* Focus Effect */
.view_children_view-button:focus {
    outline: none; /* Remove default outline */
    box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.5); /* Blue outline on focus */
}

/* Disabled State */
.view_children_view-button:disabled {
    background-color: #cfd8dc; /* Light gray for disabled state */
    color: #9e9e9e; /* Darker gray text */
    cursor: not-allowed; /* Not allowed cursor */
    box-shadow: none; /* No shadow for disabled button */
}


/* Loading and Error Messages */
.view_children_loading {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    color: #333;
}

.view_children_error-message {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    color: #dc3545; /* Red for errors */
}

/* Pagination */
.view_children_pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.view_children_pagination-button {
    margin: 0 5px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.view_children_pagination-button:hover {
    background-color: #0056b3;
}


.view_children_active-page {
    background-color: #0056b3;
    font-weight: bold;
}

/* Modal Overlay */
.view_children_modal-overlay {
    position: fixed;
    transition: opacity 0.3s ease; 
    opacity: 0; 
    visibility: hidden; 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Modal Content */
.view_children_modal-content {
    background: #fff;
    padding: 20px; 
    position: relative;
    transform: translateY(-20px); 
    transition: transform 0.3s ease, opacity 0.3s ease; 
    opacity: 0; 
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
   
}
/* Scrollable table within the modal */
.view_children_table-scrollable {
    max-height: 60vh;
    overflow-y: auto;
    margin-top: 10px;
}

.view_children_details-table {
    width: 100%;
    border-collapse: collapse;
}

.view_children_details-table th,
.view_children_details-table td {
    padding: 8px 12px;
    border: 1px solid #ddd;
    text-align: center;
}

/* When Modal is Active */
.view_children_modal-overlay.active {
    opacity: 1; /* Fully visible */
    visibility: visible; /* Allows interactions */
}

.view_children_modal-content.active {
    opacity: 1; /* Fully visible */
    transform: translateY(0); /* Slide into position */
}

/* Modal Title */
.view_children_modal-title {
   
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    border-bottom: 2px solid #007bff;
    padding-bottom: 10px;
    font-size: 20px;
    text-align: center;
   
}
.view_children_details-table{
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    background-color: #ffffff;
}

.view_children_details-table th,
.view_children_details-table td {
    padding: 12px;
    border: 1px solid #dee2e6;
    text-align: left;
    color: #495057;
    text-align: center;
}

/* Close Button Styles */
.view_children_close-button {
    padding: 10px 20px;
    background-color: #dc3545;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 2dvh;
    transition: background-color 0.3s ease;
    align-items: center;

}

.view_children_close-button:hover {
    background: #c82333;
}

/* Responsive Adjustments for the Modal */
@media (max-width: 576px) {
    .view_children_modal-content {
        padding: 20px; /* Adjust padding for smaller screens */
    }
}


/* Responsive Design */
@media (max-width: 768px) {
    .view_children_search-add-container {
        flex-direction: column;
    }

    .view_children_search-input-container {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .view_children_modal-content {
        max-width: 100%;
    }
}

@media (max-width: 576px) {
    .view_children_header-title-main {
        font-size: 22px;
    }

    .view_children_search-input {
        font-size: 14px;
    }

    .view_children_pagination-button {
        font-size: 12px;
        padding: 6px 12px;
    }
} 

@media (min-width: 840px) {
    .view_children_container  {
      padding: 20px;
      max-width: 1200px;
      box-sizing: border-box;
      margin-top: -100vh;
      
    }
  }

  @media (max-width: 840px) {
    .view_children_container {
      padding: 20px;
      max-width: 1200px;
      box-sizing: border-box;
      margin-top: 0vh;
      margin-left: 0;
      
    }
  }