
  
  /* New styles for .viewcomment-container and related elements */
  
  .viewcomment-container {
    width: 40%;
    margin: 20px;
    margin-left: 500px;
    margin-right: 9px;
    margin-top: 100dvh;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    font-family: Arial, sans-serif;
  }
  
  .viewcomment-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .viewcomment-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 1.1dvw;
  }
  
  .viewcomment-label {
    padding: 8px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    font-weight: bold;
    text-align: left;
  }
  
  .viewcomment-value {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .viewcomment-textarea {
    width: 90%;
    height: 60px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
  }
  
  .viewcomment-button-container {
    margin-top: 20px;
    text-align: center;
  }
  
  .viewcomment-add-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .viewcomment-update-button {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .viewcomment-close-button {
    background-color: #dc3545;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .viewcomment-error-message {
    color: red;
    text-align: center;
  }
  
  .viewcomment-date-picker {
    width: 100%;
  } 
  
  @media(max-width: 1920px) {
    .viewcomment-container {
      margin-left: 35dvw;
    }
  }

  @media(max-width: 1707px) {
    .viewcomment-container {
      margin-left: 38dvw;
    
    }
  }

  @media(max-width: 1489px) {
    .viewcomment-container {
      margin-left: 40dvw;
    }
  }



  /* hight media query */
 
  @media(max-height:1280px) {
    .viewcomment-container {
      margin-top: -1200px;
    }
  }

  @media(max-height:1186px) {
    .viewcomment-container {
      margin-top: -600px !important;
    }
  }

  @media(max-height:776px) {
    .viewcomment-container {
      margin-top: -700px ;
    }
  }


  @media(max-height:556px) {
    .viewcomment-container {
      margin-top: -500px !important;
    }
  }


  @media(max-height:540px) {
    .viewcomment-container {
      margin-top: -500px;
    }
  }

  @media(max-height:537px) {
    .viewcomment-container {
      margin-top: 50px;
    }
  }

  @media(max-height:529px) {
    .viewcomment-container {
      margin-top: -500px;
    }
  }


  @media(max-width:740px) and (max-height:840px){
    .viewcomment-container {
      margin-top: 10dvh !important;
      margin-left: 29dvw !important;
    }
  }

 