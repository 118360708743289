/* General Container */
.Admin_view_edu_container-Hr {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin-top: -100vh;
    margin-left: 300px;
}

/* Header and Search */
.Admin_view_edu_header-title-main {
    font-size: 2rem;
    background-color: #09099e;
    font-weight: bold;
    color: white;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    border-radius: 4px;
}

.Admin_view_edu_search-add-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.Admin_view_edu_search-input-container {
    flex: 1;
    margin-right: 10px;
}

.Admin_view_edu_search-input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 6px;
    transition: border-color 0.3s;
}

.Admin_view_edu_search-input:focus {
    border-color: #007bff;
}

/* Table Styles */
.Admin_view_edu_employee-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.Admin_view_edu_table-header {
    background-color: #f4f4f4;
}

.Admin_view_edu_employee-table th,
.Admin_view_edu_employee-table td {
    padding: 12px 16px;
    border-bottom: 1px solid #ddd;
    text-align: center;
    font-size: 14px;
}

.Admin_view_edu_even-row {
    background-color: #fafafa;
}

.Admin_view_edu_odd-row {
    background-color: #fff;
}

.Admin_view_edu_no-data {
    text-align: center;
    padding: 20px;
    color: #888;
    font-size: 16px;
}

/* Pagination */
.Admin_view_edu_pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.Admin_view_edu_pagination-button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 5px;
    transition: background-color 0.3s;
}

.Admin_view_edu_pagination-button.active {
    background-color: #0056b3;
}

.Admin_view_edu_pagination-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Modal */
.Admin_view_edu_modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.Admin_view_edu_modal-content {
    max-height: 80vh;
    overflow-y: auto;
    background: #fff;
    padding: 30px;
    border-radius: 12px;
    width: auto;
    max-width: 700px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    position: relative;
}

.Admin_view_edu_modal-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    border-bottom: 2px solid #007bff;
    padding-bottom: 10px;
}

.Admin_view_edu_education-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.Admin_view_edu_education-table th,
.Admin_view_edu_education-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 14px;
}

.Admin_view_edu_education-table th {
    background-color: #f4f4f4;
    color: #333;
}

.Admin_view_edu_education-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.Admin_view_edu_education-table tr:nth-child(odd) {
    background-color: #fff;
}

/* Buttons */
.Admin_view_edu_close-button {
    padding: 8px 16px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 14px;
}

.Admin_view_edu_close-button:hover {
    background-color: #c82333;
}

.Admin_view_edu_update-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 14px;
    margin: 15px auto;
    width: 100%;
    text-align: center;
}

.Admin_view_edu_update-button:hover {
    background-color: #0056b3;
}

.Admin_view_edu_view-button {
    padding: 8px 16px;
    font-size: 14px;
    background-color: #04aa6d;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.Admin_view_edu_view-button:hover {
    background-color: #0b4204;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .Admin_view_edu_container-Hr {
        padding: 15px;
    }
}

@media (max-width: 576px) {
    .Admin_view_edu_header-title-main {
        font-size: 18px;
    }

    .Admin_view_edu_view-button {
        font-size: 12px;
        padding: 6px 12px;
    }

    .Admin_view_edu_close-button,
    .Admin_view_edu_update-button {
        font-size: 12px;
        padding: 6px 12px;
    }
}

@media (min-width: 840px) {
    .Admin_view_edu_container-Hr {
        padding: 20px;
        max-width: 1200px;
        box-sizing: border-box;
    }
}

@media (max-width: 840px) {
    .Admin_view_edu_container-Hr {
        padding: 20px;
        max-width: 100%;
        box-sizing: border-box;
    }
}


/* Modal Overlay */
.Admin_view_edu_modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Modal Content */
.Admin_view_edu_modal-content {
    max-height: 80vh;
    overflow-y: auto;
    background: #fff;
    padding: 30px;
    border-radius: 12px;
    width: auto;
    max-width: 700px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    position: relative;
}

/* Close Icon */
.Admin_view_edu_close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    color: #dc3545;
    cursor: pointer;
    transition: color 0.3s;
}

.Admin_view_edu_close-icon:hover {
    color: #c82333;
}

/* Modal Title */
.Admin_view_edu_modal-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    border-bottom: 2px solid #007bff;
    padding-bottom: 10px;
}

