/* General Container */
.Admin_view-container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin-top:-100vh;
     margin-left: 300px;
}

/* Search and Header */
.Admin_view-search-add-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.Admin_view-search-input-container {
    flex: 1;
    margin-right: 10px;
}

.Admin_view-search-input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 6px;
    transition: border-color 0.3s;
}

.Admin_view-search-input:focus {
    border-color: #007bff;
}

.Admin_view-header-title-main {
    
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    background-color: #09099e;
    color: white;
    padding: 10px 10px;
    width:100%;
    text-align: center;
    font-size: 2dvw;
    border-radius: 4px;
}

/* Table Styles */
.Admin_view-employee-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.Admin_view-table-header {
    background-color: #f4f4f4;
}

.Admin_view-table-subheader th,
.Admin_view-employee-table th,
.Admin_view-employee-table td {
    padding: 12px 16px;
    border-bottom: 1px solid #ddd;
    text-align: left;
    font-size: 14px;
    text-align: center;
}

.Admin_view-even-row {
    background-color: #fafafa;
}

.Admin_view-odd-row {
    background-color: #fff;
}

.Admin_view-no-data {
    text-align: center;
    padding: 20px;
    color: #888;
    font-size: 16px;
}

/* Pagination */
.Admin_view-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.Admin_view-pagination-button {
    margin: 0 5px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.Admin_view-pagination-button:hover {
    background-color: #0056b3;
}

.Admin_view-active-page {
    background-color: #0056b3;
    font-weight: bold;
}

/* Buttons */
.Admin_view-view-button,
.Admin_view-update-button,
.Admin_view-close-button,
.Admin_view-submit-button,
.Admin_view-cancel-button {
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.Admin_view-view-button {
    background-color: #04AA6D;
    color: #fff;
}

.Admin_view-view-button:hover {
    background-color:  #0b4204;
}

.Admin_view-update-button,
.Admin_view-close-button {
    background-color: #28a745;
    color: #fff;
}

.Admin_view-update-button:hover,
.Admin_view-close-button:hover {
    background-color: #218838;
}

.Admin_view-cancel-button {
    background-color: #dc3545;
    color: #fff;
}

.Admin_view-cancel-button:hover {
    background-color: #c82333;
}

.Admin_view-submit-button {
    background-color: #007bff;
    color: white;
}

.Admin_view-submit-button:hover {
    background-color: #0056b3;
}

/* Loading and Empty Data */
.Admin_view-loading {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    color: #333;
}

/* Modal Styles */
.Admin_view-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 20px;
}

.Admin_view-modal-content {
    background: #fff;
    padding: 30px;
    border-radius: 12px;
    width: 100%;
    max-width: 900px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    position: relative;
    max-height: 90vh; /* Set max height */
    overflow-y: auto; /* Enable vertical scrolling */
}

.Admin_view-modal-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    border-bottom: 2px solid #007bff;
    padding-bottom: 10px;
}

/* Employee Details Section */
.Admin_view-details-container {
    display: flex;
    gap: 20px;
}

.Admin_view-image-section {
    flex-basis: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.Admin_view-employee-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.Admin_view-no-image {
    text-align: center;
    padding: 20px;
    color: #888;
}

.Admin_view-details-section {
    flex-basis: 65%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Admin_view-details-table {
    width: 100%;
    border-collapse: collapse;
}

.Admin_view-details-label {
    font-weight: bold;
    color: #555;
    padding-right: 10px;
    vertical-align: top;
}

.Admin_view-details-value {
    color: #333;
    padding-left: 10px;
}

.Admin_view-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: #dc3545;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.Admin_view-close-button:hover {
    background: #c82333;
}

/* Update Form */
/* Update Section Styles */
.Admin_view-update-section {
    margin-top: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Update Form Container */
.Admin_view-update-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Update Input Fields */
.Admin_view-update-input {
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 6px;
    transition: border-color 0.3s;
}

.Admin_view-update-input:focus {
    border-color: #007bff;
}

/* Update Button Styles */
.Admin_view-update-button {
    padding: 10px 20px;
    font-size: 14px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
    align-self: flex-start; /* Align to the start of the form */
}

.Admin_view-update-button:hover {
    background-color: #218838;
}

/* Cancel Button Styles */
.Admin_view-cancel-button {
    padding: 8px 16px;
    font-size: 14px;
    background-color: #dc3545;
    color: white;
    border: none;
    margin-left: 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
    align-self: flex-start; /* Align to the start of the form */
}

.Admin_view-cancel-button:hover {
    background-color: #c82333;
}

/* Feedback Message */
.Admin_view-update-feedback {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
}

.Admin_view-update-feedback.success {
    color: #28a745; /* Green for success */
}

.Admin_view-update-feedback.error {
    color: #dc3545; /* Red for error */
}



/* Responsive Design for Update Section */
@media (max-width: 768px) {
    .Admin_view-update-form {
        gap: 10px; /* Reduce gap for smaller screens */
    }

    .Admin_view-update-input {
        font-size: 14px; /* Smaller font size for inputs */
    }

    .Admin_view-update-button,
    .Admin_view-cancel-button {
        font-size: 12px; /* Smaller buttons on mobile */
        padding: 8px 15px;
    }
}

/* Responsive Design */
@media (max-width: 1200px) {
    .Admin_view-container {
        padding: 15px;
    }
}

@media (max-width: 768px) {
    .Admin_view-details-container {
        flex-direction: column;
    }

    .Admin_view-image-section {
        margin-bottom: 20px;
    }

    .Admin_view-modal-content {
        max-width: 100%;
    }
}

@media (max-width: 576px) {
    .Admin_view-header-title-main {
        font-size: 22px;
    }

    .Admin_view-view-button,
    .Admin_view-update-button,
    .Admin_view-close-button,
    .Admin_view-submit-button,
    .Admin_view-cancel-button {
        font-size: 12px;
        padding: 6px 12px;
    }
}

@media (min-width: 840px) {
    .Admin_view-container {
      padding: 20px;
      max-width: 1200px;
      box-sizing: border-box;
      margin-top: -100vh;
      
    }
  }

  @media (max-width: 840px) {
    .Admin_view-container {
      padding: 20px;
      max-width: 1200px;
      box-sizing: border-box;
      margin-top: 0vh;
      margin-left: 0;
      
    }
  }


  /* Add Employee Button */
.Admin_view-add-employee-button {
    padding: 10px 20px; /* Padding for button */
    font-size: 16px; /* Font size */
    background-color: #007bff; /* Primary color for the button */
    color: white; /* Text color */
    border: none; /* No border */
    border-radius: 6px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Transition effect for background color */
    margin-top: -10px; /* Top margin for spacing */
    margin-bottom: 5px;
    display: inline-block; /* Inline block to allow margin */
}

/* Hover Effect */
.Admin_view-add-employee-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

/* Active Effect */
.Admin_view-add-employee-button:active {
    background-color: #004494; /* Even darker shade when clicked */
}

/* Responsive Design for Button */
@media (max-width: 768px) {
    .Admin_view-add-employee-button {
        font-size: 14px; /* Smaller font size for mobile */
        padding: 8px 16px; /* Smaller padding */
    }
}
