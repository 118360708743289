/* AddHR.css */

.form-container {
  background-color: white;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin-top: -100vh;
  margin-left: 35%;
}

.header-bar {
  background-color: #01008A;
  padding: 2px;
  border-radius: 10px 10px 0 0;
  text-align: center;
  color: white;
  margin: -20px -20px 20px -20px;
}

.hr-form {
  display: flex;
  flex-direction: column;
}

.hr-form label {
  margin-bottom: 5px;
}

.hr-form input,
.hr-form select {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.hr-form input.error,
.hr-form select.error {
  border-color: red;
}

.error-text {
  color: red;
  margin-bottom: 10px;
}

.button-group {
  display: flex;
  justify-content: center;
}

.submit-button,
.reset-button {
  padding: 10px 20px;
  border: none;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button {
  background-color: green;
  color: white;
  margin-right: 15px;
}

.reset-button {
  background-color: red;
  color: white;
  margin-left: 15px;
}

.submit-button:hover {
  background-color: darkgreen;
  transform: scale(1.05);
}

.reset-button:hover {
  background-color: darkred;
  transform: scale(1.05);
}

.submit-button:active,
.reset-button:active {
  transform: scale(0.95);
}

/* Media Queries for Screen Sizes */
@media (max-width: 1400px) {
  .form-container {
    padding: 20px;
    max-width: 50%;
    margin-top: vh;
    margin-left: 35%;
  }
}

@media (max-width: 840px) {
  .form-container {
    padding: 20px;
    max-width: 90%;
    margin-top: 0px;
    margin-left: 5%;
  }

  .header-bar {
    margin: -20px -20px 20px -20px;
  }

  .hr-form {
    margin-left: 0;
    margin-right: 0;
  }

  .button-group {
    flex-direction: column;
  }

  .submit-button,
  .reset-button {
    width: 100%;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .form-container {
    padding: 15px;
    max-width: 95%;
    margin-top: 5px;
    margin-left: 2.5%;
  }

  .header-bar {
    margin: -15px -15px 15px -15px;
  }

  .button-group {
    flex-direction: column;
  }

  .submit-button {
    width: 100%;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
}
  .reset-button {
    width: 100%;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
  }
}
