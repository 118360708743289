.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgb(230, 233, 240);
}

.login-container {
  display: flex;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  max-width: 900px;
  width: 100%;
}

.left-side {
  padding: 40px;
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.left-side .logo {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
  margin-left: 50px;
  margin-right: 50px;
}

.left-side h1 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.left-side p {
  margin: 5px 0;
  font-size: 14px;
}

.left-side h2 {
  margin: 20px 0;
  font-size: 20px;
  font-weight: bold;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.input-container {
  position: relative;
  width: 100%;
  margin: 10px 0;
  margin-top: 10px;
}

.icon-1 {
  position: absolute;
  left: 20px;
  top: 35%;
  margin-top: 3px;
  transform: translateY(-50%);
  color: #888;
}

.input-field {
  width: 100%;
  padding: 10px 10px 10px 40px; /* Add padding to make space for the icon */
  border: 1px solid #e5e6eb;
  border-radius: 5px;
  background-color: #d9dbe2;
  height: auto;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  display: block;
}

.forgot-password {
  align-self: center;
  margin-bottom: 20px;
  font-size: 12px;
  color: #020a12;
  font-weight: bolder;
  text-decoration: none;
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.login-button, .clear-button {
  width: 45%;
  padding: 8px;
  background-color: #01008A;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease-in-out, transform 0.2s;
}

.login-button:hover, .clear-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.right-side {
  flex: 1;
  background: linear-gradient(to right, #01008A, #a933ff);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 40px;
}

.info-box {
  text-align: center;
}

.info-box h2 {
  font-size: 24px;
  font-weight: bold;
}

.info-image {
  margin-top: 20px;
  width: 300px;
  height: auto;
}

/* Media Queries for Responsiveness */


@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
    max-width: 100%;
  }

  .left-side, .right-side {
    width: 100%;
    padding: 20px;
  }

  .left-side .logo {
    width: 80px;
  }

  .left-side h1 {
    font-size: 20px;
  }

  .left-side h2 {
    font-size: 18px;
  }

  .login-button, .clear-button {
    width: 70%;
  }
}

@media (max-width: 480px) {
  .left-side h1 {
    font-size: 18px;
  }

  .left-side h2 {
    font-size: 16px;
  }

  .info-image {
    width: 100%;
    height: auto;
  }
}

.eye-icon {
  position: absolute;
  right: 10px;
  top: 35%;
  margin-top: 3px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #888;
}
