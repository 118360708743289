/* General Container */
.View_Parent_container-Hr {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin-top:-100vh;
     margin-left: 300px;
}

/* Search and Header */
.View_Parent_search-add-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.View_Parent_search-input-container {
    flex: 1;
    margin-right: 10px;
}

.View_Parent_search-input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 6px;
    transition: border-color 0.3s;
}

.View_Parent_search-input:focus {
    border-color: #007bff;
}

.View_Parent_header-title-main {
   
    font-weight: bold;
    background-color: #09099e;
    color: white;
    padding: 10px 10px;
    width:100%;
    text-align: center;
    font-size: 2dvw;
    margin-bottom: 20px;
    border-radius: 4px;
}

/* Table Styles */
.View_Parent_employee-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    background-color: #ffffff;
}

.View_Parent_table-header {
    background-color: #f4f4f4;
}

.View_Parent_table-subheader th,
.View_Parent_employee-table th,
.View_Parent_employee-table td {
    padding: 12px 16px;
    border-bottom: 1px solid #ddd;
    text-align: center;
    font-size: 14px;
}

.View_Parent_even-row {
    background-color: #fafafa;
}

.View_Parent_odd-row {
    background-color: #fff;
}

.View_Parent_no-data {
    text-align: center;
    padding: 20px;
    color: #888;
    font-size: 16px;
}

/* Button Styles */
.View_Parent_view-button {
    background-color: #04AA6D; /* Primary blue color */
    color: white; /* White text */
    padding: 10px 20px; /* Increased padding for a better look */
    border: none; /* No border */
    border-radius: 6px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
    font-size: 16px; /* Font size */
    font-weight: 500; /* Slightly bolder text */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Added shadow for depth */
}

.View_Parent_view-button:hover {
    background-color: #0b4204; 
    transform: translateY(-2px); 
}

/* Pagination Styles */
.View_Parent_pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.View_Parent_pagination-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
}

.View_Parent_pagination-button.active {
    background-color: #0056b3;
}

/* Modal Styles */
.View_Parent_modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); /* Dark background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.View_Parent_table-subheader th,
.View_Parent_table-subheader td{
    padding: 12px;
    border: 1px solid #dee2e6;
    text-align: left;
    color: #495057;
    text-align: center;
}

.View_Parent_modal-content {
    background: #fff;
    padding: 30px;
    border-radius: 12px;
    width: auto;
    max-width: 700px; /* Maximum width of modal */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    position: relative;
}

.View_Parent_modal-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center; /* Center title */
    border-bottom: 2px solid #007bff; /* Bottom border */
    padding-bottom: 10px;
}

.View_Parent_modal-buttons {
    display: flex;
    justify-content: flex-end;
}

.View_Parent_close-button {
   
    padding: 10px 15px;
    padding: 10px 20px;
    background-color: #dc3545;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 2dvh;
    transition: background-color 0.3s ease;
}
.View_Parent_details-table{
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    background-color: #ffffff;
}

.View_Parent_details-table th,
.View_Parent_details-table td {
    padding: 12px;
    border: 1px solid #dee2e6;
    text-align: left;
    color: #495057;
    text-align: center;
}
.View_Parent_close-button:hover {
    background-color: #c82333;
}

.View_Parent_table-subheader th{
    background-color: #f4f4f4; /* Header background */
    color: #333; /* Header text color */
}

.View_Parent_table-subheader tr:nth-child(even) {
    background-color: #f9f9f9; /* Even row background */
}


@media (min-width: 840px) {
    .View_Parent_container-Hr{
      padding: 20px;
      max-width: 1200px;
      box-sizing: border-box;
      margin-top: -100vh;
      
    }
  }

  @media (max-width: 840px) {
    .View_Parent_container-Hr {
      padding: 20px;
      max-width: 1200px;
      box-sizing: border-box;
      margin-top: 0vh;
      margin-left: 0;
      
    }
  }

  