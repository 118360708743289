/* General Container */
.View_Emergency_container-Hr {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin-top:-100vh;
     margin-left: 300px;
}

/* Search and Header */
.View_Emergency_search-add-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.View_Emergency_search-input-container {
    flex: 1;
    margin-right: 10px;
}

.View_Emergency_search-input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 6px;
    transition: border-color 0.3s;
}

.View_Emergency_search-input:focus {
    border-color: #007bff;
}

.View_Emergency_header-title-main {

    font-weight: bold;
    background-color: #09099e;
    color: white;
    padding: 10px 10px;
    width:100%;
    text-align: center;
    font-size: 2dvw;
    margin-bottom: 20px;
    border-radius: 4px;
}

/* Table Styles */
.View_Emergency_employee-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.View_Emergency_table-header {
    background-color: #f4f4f4;
}

.View_Emergency_table-subheader th,
.View_Emergency_employee-table th,
.View_Emergency_employee-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 14px;
    
}

.View_Emergency_details-table th {
    background-color: #f4f4f4; /* Header background */
    color: #333;
}

.View_Emergency_details-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.View_Emergency_details-table tr:nth-child(odd) {
    background-color: #fff;
}
.View_Emergency_no-data {
    text-align: center;
    padding: 20px;
    color: #888;
    font-size: 16px;
}

/* Button Styles */
.View_Emergency_view-button {
    background-color: #04AA6D; /* Primary blue color */
    color: white; /* White text */
    padding: 10px 20px; /* Increased padding for a better look */
    border: none; /* No border */
    border-radius: 6px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
    font-size: 16px; /* Font size */
    font-weight: 500; /* Slightly bolder text */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Added shadow for depth */
}

.View_Emergency_view-button:hover {
    background-color: #0b4204; /* Darker blue on hover */
    transform: translateY(-2px); /* Lift effect on hover */
}

/* Pagination Styles */
.View_Emergency_pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.View_Emergency_pagination-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
}

.View_Emergency_pagination-button.active {
    background-color: #0056b3;
}

/* Modal Styles */
.View_Emergency_modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.View_Emergency_modal-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;
   
}
.View_Emergency_details-table{
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    background-color: #ffffff;
}

.View_Emergency_details-table th,
.View_Emergency_details-table td{
    padding: 12px;
    border: 1px solid #dee2e6;
    text-align: left;
    color: #495057;
}

.View_Emergency_modal-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #343a40;
}

.View_Emergency_modal-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.View_Emergency_close-button {
    background-color: #ff4d4d;
    padding: 10px 20px;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.View_Emergency_close-button:hover {
    background-color: #d44d4d;
}

.comment-table-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-wrap: wrap;
  }
  
  .comment-table-pagination-button {
    padding: 8px 12px;
    margin: 0 5px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    transition: background-color 0.3s ease;
  }
  
  .comment-table-pagination-button-active {
    background-color: #0056b3;
  }
  


@media (min-width: 840px) {
    .View_Emergency_container-Hr{
      padding: 20px;
      max-width: 1200px;
      box-sizing: border-box;
      margin-top: -100vh;
      
    }
  }

  @media (max-width: 840px) {
    .View_Emergency_container-Hr {
      padding: 20px;
      max-width: 1200px;
      box-sizing: border-box;
      margin-top: 0vh;
      margin-left: 0;
      
    }
  }