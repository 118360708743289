.comments-container {
  width: 75%;
  margin-left: 10dvw; /* Reduced margin to bring closer to sidebar */
  margin-top: -100dvh;
  margin-right: 9dvh;
  background-color: white;
  padding: 10px;
  display: block;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.comments-headerTitle {
  background-color: #09099e;
  color: white;
  padding: 10px 0;
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.comments-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.comments-thTd {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.comments-th {
  background-color: #ddd;
  font-weight: bold;
}

.comments-button {
  margin: 10px;
  padding: 8px 12px;
  cursor: pointer;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.comments-button:hover {
  background-color: #0056b3;
}

.comments-inputField {
  width: 90%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.comments-inputField:focus {
  border-color: #007BFF;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

.comments-pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.comments-paginationButton {
  background-color: #8f9294;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.comments-paginationButtonActive {
  background-color: #0056b3;
}

.comments-paginationButton:hover {
  background-color: #0056b3;
}

/* Responsive Media Queries */
@media(max-width: 1920px) {
  .comments-container {
    margin-left: 24dvw; 
  }
}

/* For smaller laptops and tablets */
@media(max-width: 1023px) {
  .comments-container {
    margin-left: 30dvw; 
  }
}

@media(max-width: 975px) {
  .comments-container {
    margin-left: 32dvw;
  }
}

@media(max-width: 933px) {
  .comments-container {
    margin-left: 33dvw;
  }
}

@media(max-width: 840px) {
  .comments-container {
    margin-left: 10dvw; 
    margin-top: 10vh;
  }
}

@media(max-width: 768px) {
  .comments-container {
    
    margin-left: 15dvw; 
    margin-top: 12vh;
  }
}


@media(max-width: 600px) {
  .comments-container {
   
    margin-left: 40vw;
    margin-top: 10vh;
  }
}




@media(max-width: 740px) and (max-height: 840px) {
  .comments-container {
    margin-top: 10dvh !important;
    margin-left: 10dvw !important; 
  }
}


@media(max-width: 480px) and (max-height: 600px) {
  .comments-container {
    margin-top: 15vh;
    margin-left: 5vw;
  }
}