/* Dashboard.css */

.dashboard-container {
  margin-left: 290px;
  margin-top: -700px; /* Adjust for sidebar space if necessary */
  padding: 20px;
}

.top-bar {
  display: flex;
   justify-content: space-between;
      align-items: center;
        background-color: #e0e8ff;
          padding: 20px;
       border-radius: 10px;
  margin-bottom: 20px;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
      transition: background-color 0.3s ease, color 0.3s ease; 
}

.rounded-text {
  display: inline-block;
      padding: 1px; 
         border-radius: 10px; 
  background-color: #e0e8ff; /* Optional: to see the rounded effect more clearly */
}

.top-bar h1 {
  color: #2f2e41;
   font-size: 1.6em;
     flex: 1;
       letter-spacing: 0.05em;
        font-family:Arial, Helvetica, sans-serif;

  font-family: 'Roboto', sans-serif;
     font-weight: bold;
       text-transform: uppercase; 
         letter-spacing: 0.05em; 
           text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Text shadow for depth */
             line-height: 1.2; /* Line height */
}

.top-bar h2 {
  color: #7b61ff;
    font-size: 1.9em;
       margin-left: auto;
         margin-right: -70px;
             font-size: 40px;
                 font-family: 'Roboto', sans-serif; 
                    font-weight: bold;
                   text-transform: uppercase;
                  letter-spacing: 0.05em;
               text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4); 
             line-height: 1.2; 
           position: relative;
       z-index: 2; /* Higher z-index to place above the image */
      right: 20px;
}

.top-bar-icon img {
  height: 112px;
     width: 110px;
       position: relative;
     z-index:1; /* Lower z-index to place below the h2 text */
  right: 19%;
}

.divisions {
  display: grid;
     grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.division {
  display: flex;
       flex-direction: column;
         justify-content: center;
           align-items: center;
            padding: 20px;
           border-radius: 10px;
         border: none;
       font-size: 1.5em;
  cursor: pointer;
}

/* Specific styling for each division button */
.division1 {
  background-color: #e0f7fa;
      color: #00695c;
        padding: 20px; /* Padding inside the division */
           border-radius: 10px;  
         box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  
       transition: background-color 0.3s ease, color 0.3s ease; 
}

.division2 {
  background-color: #fff9c4;
     color: #fbc02d;
        padding: 20px; /* Padding inside the division */
          border-radius: 10px;  
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  
    transition: background-color 0.3s ease, color 0.3s ease;  
}

.division3 {
  background-color: #c8e6c9;
       color: #2e7d32;
        padding: 20px;  
         border-radius: 10px;  
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
            transition: background-color 0.3s ease, color 0.3s ease;  
}

.division4 {
  background-color: #f8bbd0;
  color: #e91e63;

  padding: 20px;  
border-radius: 10px;  
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  
transition: background-color 0.3s ease, color 0.3s ease;  
}

.division5 {
  background-color: #b3e5fc;
  color: #0288d1;

  padding: 20px;  
border-radius: 10px;  
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
transition: background-color 0.3s ease, color 0.3s ease;  
}

.division6 {
  background-color: #d1c4e9;
  color: #673ab7;

  padding: 20px; 
border-radius: 10px;  
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
transition: background-color 0.3s ease, color 0.3s ease;  
}

.division7 {
  background-color: #cafcb3;
  color: #85d102;

  padding: 20px;  
border-radius: 10px;  
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
transition: background-color 0.3s ease, color 0.3s ease;  
}

.division8 {
  background-color: #e9c4c4;
  color: #b73a3a;

  padding: 20px; 
border-radius: 10px;  
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
transition: background-color 0.3s ease, color 0.3s ease;  
}



/*============*/



/* Hover effects for all division buttons */

.division1:hover, .division3:hover{
/*transform: scale(1.05) rotate(2deg);  Slightly enlarge and rotate button on hover */
box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
transition: transform 2.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
}


.division2:hover, .division5:hover {
/*transform: scale(1.05) rotate(-2deg); /* Slightly enlarge and rotate button on hover */
box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
transition: transform 2.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
}

.division6:hover, .division4:hover {
/* transform: scale(1.05) rotate(2deg); /* Slightly enlarge and rotate button on hover */
box-shadow: 0 18px 16px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
transition: transform 2.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
}

/* Keyframes for button fade-in animation */
@keyframes buttonFadeIn {
from {
  opacity: 0; /* Start from transparent */
}
to {
  opacity: 1; /* End with fully opaque */
}
}

/*========================================== Testing Area ====================*/

@media (max-width: 2245px){
  .dashboard-container{
    margin-top: -830px;

  }
} 
/*..............after change ....................*/

@media (max-width: 1280px){
  .dashboard-container{
    margin-top: -1030px;

  }
}

@media (max-width: 1024px){
  .dashboard-container{
    margin-top: -1030px;

  }
}

@media (max-width: 889px){
  .dashboard-container{
    margin-top: -730px;

  }
}
/*...........................*/

@media (max-width: 765px){

  .top-bar h1{
    font-size: 30px;
  }

  .top-bar h2{
    font-size: 20px;
  }

  .dashboard-container{
  }
}

@media (max-width: 884px){

  .dashboard-container{
  }
  
  .divisions{
    grid-template-columns: 1fr;
  }
}

@media (max-width: 841px){
  .dashboard-container{
    margin-top: -10px;
  }

  .top-bar,.divisions {
    margin-left: -300px;
    margin-right: 15px;
  }

  .divisions{

    padding: 30px;
    margin-top: -20px;
  }
}

@media (max-width: 740px){
  
  .dashboard-container{
    margin-top: -15px;
  }

  .top-bar{
    margin-left: -300px;
    margin-right: -30px;
  }

  .divisions{
    margin-left: -300px;
    margin-right: -30px;
    padding: 50px;
    margin-top: -50px;
  }
}

/*-----------------------------------------------------*/

@media (max-height: 661px) and (max-width: 2245px){
  .dashboard-container{
    margin-top: -620px;
  }
}

@media (max-height: 511px) and (max-width: 2245px){
.dashboard-container{
  margin-top: -430px;
}
}

@media (max-height: 323px) and (max-width: 2245px){
  .dashboard-container{
    margin-top: -250px;
  }
}


/*-----------------------------------------------------*/


@media (max-height: 661px) and (max-width: 765px){
.dashboard-container{
  margin-top: -9px;
  margin-right: -10px;
}

.top-bar{
  margin-right: -20px;
}

.divisions{
  margin-right: -19px;
}

}

/*-----------------------------------------------------*/

@media (max-height: 595px) and (max-width: 884px){
.dashboard-container{
  margin-top: -640px;
}
}

/*-----------------------------------------------------*/

@media (max-height: 595px) and (max-width: 841px){
.dashboard-container{
  margin-top: -20px;
}

.top-bar, .divisions{
  margin-right: -25px;
}

}

/*-----------------------------------------------------*/

@media (max-height: 661px) and (max-width: 740px){

}

/*-----------------------------------------------------*/


/*.......................................................*/


.employee-details {
  margin-top: 20px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
}

.employee-details h3 {
  margin-bottom: 15px;
  font-size: 1.5em;
}

.employee-details table {
  width: 100%;
  border-collapse: collapse;
}

.employee-details th, .employee-details td {
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
}

.employee-details th {
  background-color: #e0e8ff;
}

.employee-details tbody tr.highlight-row {
  background-color: #d4edda; /* Light green for current employees */
}

.employee-details tbody tr.inactive-row {
  background-color: #f8d7da; /* Light red for discharged employees */
}

.employee-details th, .employee-details td {
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
}

.employee-details th {
  background-color: #e0e8ff;
}

.search-bar {
  margin-bottom: 15px;
  padding: 10px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.pagination button {
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #e0e8ff;
}

.pagination button.active-page {
  background-color: #007bff;
  color: white;
  font-weight: bold;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}


/*...............................................*/

/* Collapse the table into a stacked layout for small screens */
@media (max-width: 768px) {
  .employee-details table,
  .employee-details tbody,
  .employee-details tr,
  .employee-details th,
  .employee-details td {
    display: block; /* Make table elements block-level */
  }

  .employee-details tr {
    margin-bottom: 10px; /* Add spacing between rows */
    border-bottom: 1px solid #ddd; /* Separate rows visually */
  }

  .employee-details th,
  .employee-details td {
    text-align: left; /* Align text to the left */
    padding: 10px; /* Add padding for readability */
  }

  .employee-details th {
    background-color: #e0e8ff; /* Highlight headers */
    border: none; /* Remove borders */
    font-weight: bold;
    display: inline-block; /* Display headers inline with data */
    width: 40%; /* Assign width for labels */
  }

  .employee-details td {
    border: none; /* Remove borders for data cells */
    display: inline-block; /* Align with headers */
    width: 60%; /* Assign width for data values */
  }

  .employee-details tbody tr.highlight-row {
    background-color: #d4edda; /* Light green for current employees */
  }

  .employee-details tbody tr.inactive-row {
    background-color: #f8d7da; /* Light red for discharged employees */
  }
}

/* Adjust font sizes for very small screens */
@media (max-width: 576px) {
  .employee-details th,
  .employee-details td {
    font-size: 0.9rem; /* Reduce font size */
    padding: 8px; /* Adjust padding */
  }
}

/* Alternative layout for smaller heights */
@media (max-height: 500px) {
  .employee-details table {
    font-size: 0.85rem; /* Reduce font size */
  }
}
