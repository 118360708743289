.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #ffffff; /* White background */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for a clean look */
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: hidden;
  transition: width 0.3s ease;
}

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 10px;
  border-radius: 20px; /* Rounded corners for a softer look */
  background-color: #f8f9fa; /* Light background for items */
}

.sidebar-item:hover {
  background-color: #e9ecef; /* Light grey for hover */
  transform: translateX(5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Slightly larger shadow on hover */
}

.sidebar-item.active {
  border-left: 5px solid #01008A; /* Blue color for active item */
  background-color: #e9ecef; /* Same as hover color */
  transform: translateX(5px);
}

.icon {
  margin-right: 15px;
  font-size: 1.5em;
  color: #01008A; /* Matching icon color with active border */
}

span {
  font-size: 1.1em;
  margin-left: 10px;
  color: #495057; /* Dark text color for better readability */
}

/* Submenu Styles */
.submenu {
  margin-left: 30px;
  animation: fadeIn 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
}

.submenu-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border-radius: 20px;
  background-color: #ffffff; /* White background to match sidebar */
}

.submenu-item:hover {
  background-color: #f1f3f5; /* Light grey for hover */
  transform: translateX(5px);
}

.submenu-icon {
  margin-right: 15px;
  font-size: 1.3em;
  color: #01008A; /* Matching submenu icon color */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 200px;
  }
  .icon {
    font-size: 1.4em;
  }
  span {
    font-size: 1.1em;
  }
}

@media (max-width: 480px) {
  .sidebar {
    width: 150px;
  }
  .sidebar-item {
    padding: 10px 15px;
  }
  .icon {
    font-size: 1.2em;
  }
  span {
    font-size: 1em;
  }
}


/* Responsive Design */
@media (max-width: 768px) {
  .sidebar {
    width: 200px;
  }
  .icon {
    font-size: 1.2em;
  }
  span {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  /*.sidebar {
    width: 150px;
  }*/
  .sidebar-item {
    padding: 10px 15px;
  }
  .icon {
    font-size: 1em;
  }
  span {
    font-size: 0.9em;
  }
}

@media (max-width: 840px) {
  .sidebar {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;
    box-shadow: none;
  }

  .sidebar-item {
    flex: 1;
    justify-content: center;
    margin-bottom: 0;
    padding: 10px;
    border-radius: 5px;
  }

  .sidebar-item.active {
    border-left: none;
    border-bottom: 3px solid #E8E8E8;
  }

  .icon {
    margin-right: 0;
    font-size: 1.2em;
  }

  span {
    display: none; /* Hide text on very small screens */
  }
}